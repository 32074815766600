import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import twitterIcon from '../../content/assets/icons/twitter.svg'

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const Contact = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const [userEmail, setUserEmail] = useState('')
  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useState(null)

  const handleSubmit = async e => {
    e.preventDefault()

    setIsLoading(true)
    setStatusMessage(null)

    try {
      const response = await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': 'contact', message, email: userEmail }),
      })

      setIsLoading(false)

      if (response.status === 200) {
        setUserEmail('')
        setMessage('')
        setStatusMessage('Message sent!')
      } else {
        setStatusMessage('Error sending message.')
      }
    } catch (e) {
      console.log(e)
      setIsLoading(false)
      setStatusMessage('Error sending message.')
    }
  }

  return (
    <Layout location={location} title={siteTitle}>
      <div className="container contact">
        <SEO title="Contact" />

        <div className="contact-header">Contact Me!</div>

        <div className="contact-links">
          <div>(724) 612-8632</div>
          <a href="mailto:bolandryanm@gmail.com" className="standard-link --light">
            bolandryanm@gmail.com
          </a>
          <a
            href="https://twitter.com/bolandrm"
            target="_blank"
            rel="noopener noreferrer"
            className="standard-link --light"
          >
            <img src={twitterIcon} className="twitter-icon" alt="twitter" />
            @bolandrm
          </a>
        </div>

        <div className="divider" />

        <div className="contact-message-header">Send me a message:</div>

        <form className="contact-form" name="contact" data-netlify="true" onSubmit={handleSubmit}>
          <div className="contact-form-row">
            <label>Your Email Address</label>
            <input
              type="email"
              name="email"
              placeholder="name@example.com"
              onChange={e => setUserEmail(e.target.value)}
              value={userEmail}
            />
          </div>
          <div className="contact-form-row">
            <label>Message</label>
            <textarea
              name="message"
              placeholder="Hello!"
              value={message}
              onChange={e => setMessage(e.target.value)}
            />
          </div>
          {statusMessage && (
            <div className="contact-form-row">
              <label />
              <p>{statusMessage}</p>
            </div>
          )}
          <div className="contact-form-row">
            <label />
            <button disabled={isLoading || !message.length || !userEmail.length}>
              Send message
            </button>
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default Contact

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
